.faq-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 2rem;
  background-color: #f9f9f9;
  border: 2px solid #ddd;
  border-radius: 8px;
  margin-bottom: 1.5rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.faq-container h1 {
  text-align: center;
  color: #15202f;
  margin-bottom: 2rem;
}

.faq-item {
  margin-bottom: 1rem;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  overflow: hidden;
}

.faq-question {
  width: 100%;
  background-color: #f5f5f5;
  border: none;
  padding: 1rem;
  text-align: left;
  font-size: 1.1rem;
  font-weight: bold;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: background-color 0.3s ease;
}

.faq-question:hover {
  background-color: #e0e0e0;
}

.faq-icon {
  font-size: 1.5rem;
  color: #15202f;
}

.faq-answer {
  background-color: white;
  padding: 0 1rem;
  max-height: 0;
  overflow: hidden;
  transition: all 0.3s ease-out;
}

.faq-item.open .faq-answer {
  max-height: 1000px;
  padding: 1rem;
}

.faq-answer p {
  margin: 0;
  color: #333;
  line-height: 1.6;
}

@keyframes slideDown {
  from {
    opacity: 0;
    transform: translateY(-7px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.faq-item.open .faq-answer {
  animation: slideDown 1s ease-out;
}
