.plans-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem 1rem;
}

.plans-title {
  font-size: 2.5rem;
  font-weight: bold;
  text-align: center;
  margin-bottom: 3rem;
}

.plans-grid {
  display: flex;
  flex-direction: row;
  gap: 2rem;
}

.plan-card {
  background-color: white;
  border-radius: 0.5rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 1.5rem;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.plan-title {
  font-weight: bold;
  margin-top: 0px;
}

.plan-price {
  font-size: 1.875rem;
  font-weight: bold;
  margin-bottom: 1.5rem;
}

.plan-features {
  margin-bottom: 1.5rem;
  flex-grow: 1;
}

.feature-item {
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
}

.feature-icon {
  width: 1rem;
  height: 1rem;
  margin-right: 0.5rem;
  color: #10b981;
}

.subscribe-button {
  width: 100%;
  padding: 12px 16px;
  background-color: #15202f;
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.1s;
  justify-content: space-between;
  align-items: center;
  text-align: center;
}

.subscribe-button:hover {
  background-color: #667589;
}

.subscribe-button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.loading {
  text-align: center;
  margin-top: 2rem;
}

.button-spinner {
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@media (max-width: 768px) {
  .plans-grid {
    grid-template-columns: 1fr;
    flex-direction: column;
  }
}
