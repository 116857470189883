.account-page {
  min-height: 70vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
}

.account-card {
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 32px;
  width: 100%;
  max-width: 400px;
}

.account-header {
  margin-bottom: 24px;
}

.account-header h1 {
  font-size: 24px;
  font-weight: bold;
  color: #333;
  margin-bottom: 8px;
}

.account-header p {
  margin-left: 5%;
  font-size: 12px;
  color: #666;
}

.account-actions {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.account-button {
  width: 100%;
  padding: 12px 16px;
  background-color: #15202f;
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.1s;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.account-button:hover {
  background-color: #667589;
}

.account-button:active {
  transform: scale(0.98);
}

.account-button:disabled {
  background-color: #667589;
  cursor: not-allowed;
}

.button-content {
  display: flex;
  align-items: center;
}

.button-icon {
  margin-right: 8px;
  font-size: 20px;
}

.button-arrow {
  font-size: 20px;
}

.button-spinner {
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@media (max-width: 480px) {
  .account-card {
    padding: 24px;
  }

  .account-header h1 {
    font-size: 20px;
  }

  .account-button {
    font-size: 14px;
  }
}
