.success-container {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  padding: 20px;
  background-color: #f9f9f9;
}

.success-card {
  background-color: white;
  border-radius: 12px;
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
  padding: 2.5rem;
  width: 100%;
  max-width: 800px;
  text-align: center;
}

.success-icon {
  font-size: 5rem;
  margin-bottom: 1.5rem;
}

.success-title {
  font-size: 2.25rem;
  color: #15202f;
  margin-bottom: 1.5rem;
  font-weight: bold;
}

.success-message {
  font-size: 1.125rem;
  color: #4b5563;
  margin-bottom: 2rem;
  line-height: 1.6;
}

.social-proof {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #e0f2fe;
  border-radius: 8px;
  padding: 1rem;
  margin-bottom: 2rem;
}

.social-proof svg {
  color: #0284c7;
  margin-right: 0.75rem;
}

.social-proof p {
  color: #0369a1;
  font-size: 1rem;
  font-weight: 500;
}

.success-details {
  text-align: left;
  margin-bottom: 2rem;
}

.success-details h2 {
  font-size: 1.5rem;
  color: #15202f;
  margin-bottom: 1rem;
}

.success-details ul {
  list-style-type: none;
  padding-left: 0;
}

.success-details li {
  margin-bottom: 0.75rem;
  color: #4b5563;
  font-size: 1.125rem;
  display: flex;
  align-items: center;
}

.success-details li:before {
  content: "✓";
  color: #10b981;
  font-weight: bold;
  display: inline-block;
  width: 1.5em;
  margin-right: 0.5em;
}

.button-group {
  display: flex;
  justify-content: center;
  gap: 1rem;
  flex-wrap: wrap;
}

.success-button {
  border: none;
  border-radius: 6px;
  padding: 0.75rem 1.5rem;
  font-size: 1rem;
  cursor: pointer;
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.success-button.primary {
  background-color: #15202f;
  color: white;
}

.success-button.primary:hover {
  background-color: #667589;
}

.success-button.secondary {
  background-color: #e5e7eb;
  color: #4b5563;
}

.success-button.secondary:hover {
  background-color: #d1d5db;
}

.fade-in {
  animation: fadeIn 0.5s ease-out;
}

.animate-bounce {
  animation: bounce 1s infinite;
}

.animate-fade-in {
  opacity: 0;
  animation: fadeIn 0.5s ease-out forwards;
}

@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

@keyframes bounce {
  0%, 100% { transform: translateY(-25%); animation-timing-function: cubic-bezier(0.8, 0, 1, 1); }
  50% { transform: translateY(0); animation-timing-function: cubic-bezier(0, 0, 0.2, 1); }
}

@media (max-width: 640px) {
  .success-card {
    padding: 1.5rem;
  }
  
  .success-title {
    font-size: 1.75rem;
  }
  
  .success-message, .success-details li {
    font-size: 1rem;
  }
  
  .button-group {
    flex-direction: column;
  }
  
  .social-proof {
    flex-direction: column;
    text-align: center;
  }
  
  .social-proof svg {
    margin-right: 0;
    margin-bottom: 0.5rem;
  }
}