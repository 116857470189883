@import url("https://maxcdn.bootstrapcdn.com/font-awesome/4.3.0/css/font-awesome.min.css");
@import url(https://fonts.googleapis.com/css?family=Open+Sans:400,300);
body {
  margin: 0;
  padding: 0;
  font-family: "Roboto", sans-serif;
}

.signup-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 70vh;
}

.alert {
  margin: 10px 0px;
  padding: 12px;
  border-radius: 5px;
  transition: opacity 1600ms ease-in-out;
  font-family: "Open Sans", sans-serif;
  font-size: 0.9rem;
  font-weight: 300;
  letter-spacing: 1px;
}

.alert:hover {
  cursor: pointer;
}

.alert:before {
  padding-right: 12px;
}

.alert:after {
  content: "\f00d";
  font-family: "FontAwesome";
  float: right;
  padding: 3px;
  &:hover {
    cursor: pointer;
  }
}

.alert-error {
  color: #d8000c;
  background-color: #ffbaba;
  border: 1px solid darken(#ffbaba, 15%);
}

.alert-error:before {
  content: "\f057";
  font-family: "FontAwesome";
}

.signup-box {
  background-color: white;
  border-radius: 10px;
  padding: 40px;
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.1);
  width: 450px;
  text-align: center;
  animation: fadeIn 0.5s ease-out;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.signup-title {
  color: #15202f;
  margin-bottom: 30px;
  font-weight: 600;
}

.form-group {
  margin-bottom: 20px;
}

.form-input {
  width: 100%;
  padding: 12px;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-size: 14px;
  transition: border-color 0.3s ease;
}

.form-input:focus {
  border-color: #15202f;
  outline: none;
  animation: inputHighlight 0.3s ease;
}

@keyframes inputHighlight {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.02);
  }
  100% {
    transform: scale(1);
  }
}

.signup-button {
  width: 100%;
  padding: 12px;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
  background-color: #15202f;
  color: white;
  margin-bottom: 15px;
}

.signup-button:hover {
  background-color: #1a2e47;
  animation: pulse 1s infinite;
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.05);
  }
  100% {
    transform: scale(1);
  }
}

.login-link {
  font-size: 14px;
  color: #666;
}

.login-link a {
  color: #15202f;
  text-decoration: none;
  font-weight: 600;
  transition: color 0.3s ease;
}

.login-link a:hover {
  color: #1a2e47;
}

.error-message {
  color: #ff4d4f;
  margin-bottom: 15px;
}

.loading {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  font-size: 24px;
  color: #15202f;
}

.password-strength-group {
  margin-top: 10px;
}

.password-strength-meter {
  width: 100%;
  display: flex;
  justify-content: stretch;
}

.meter-block {
  height: 4px;
  background: #ccc;
  margin-right: 6px;
  flex-grow: 1;
}

.meter-block:last-child {
  margin-right: 0;
}

.password-strength-message {
  font-size: 12px;
  height: 1em;
  text-align: right;
  margin-top: 3px;
  position: relative;
}

.message-item {
  position: absolute;
  right: 0;
  opacity: 0;
  transition: opacity 0.2s;
}

.password-strength-group[data-strength="1"] .meter-block:nth-child(-n + 1) {
  background: #cc3d04;
}

.password-strength-group[data-strength="2"] .meter-block:nth-child(-n + 2) {
  background: #ffc43b;
}

.password-strength-group[data-strength="3"] .meter-block:nth-child(-n + 3) {
  background: #9ea60a;
}

.password-strength-group[data-strength="4"] .meter-block:nth-child(-n + 4) {
  background: #289116;
}

.password-strength-group[data-strength="1"] .message-item:nth-child(1),
.password-strength-group[data-strength="2"] .message-item:nth-child(2),
.password-strength-group[data-strength="3"] .message-item:nth-child(3),
.password-strength-group[data-strength="4"] .message-item:nth-child(4) {
  opacity: 1;
}

.terms-checkbox {
  margin-top: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.terms-text-container {
  font-size: 14px;
  margin-bottom: 10px;
  text-align: center;
}

.checkbox-container {
  display: flex;
  align-items: center;
  cursor: pointer;
  user-select: none;
}

.checkbox-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.checkmark {
  position: relative;
  height: 20px;
  width: 20px;
  background-color: #eee;
  border: 1px solid #ddd;
  border-radius: 3px;
  margin-right: 8px;
  transition: all 0.2s ease-in-out;
}

.checkbox-container:hover input ~ .checkmark {
  background-color: #ccc;
}

.checkbox-container input:checked ~ .checkmark {
  background-color: #2196f3;
  border-color: #2196f3;
}

.checkmark:after {
  content: "";
  position: absolute;
  display: none;
  left: 6px;
  top: 2px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}

.checkbox-container input:checked ~ .checkmark:after {
  display: block;
}

.checkbox-label {
  font-size: 14px;
}

.terms-text-container a {
  color: #007bff;
  text-decoration: none;
}

.terms-text-container a:hover {
  text-decoration: underline;
}

@media (max-width: 480px) {
  .terms-text-container {
    font-size: 12px;
  }

  .checkbox-label {
    font-size: 12px;
  }

  .checkmark {
    height: 18px;
    width: 18px;
  }

  .checkmark:after {
    left: 5px;
    top: 2px;
    width: 4px;
    height: 8px;
  }
}

.password-input-container {
  position: relative;
  display: flex;
  align-items: center;
}

.password-toggle {
  position: absolute;
  right: 10px;
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.password-toggle-icon {
  width: 20px;
  height: 20px;
  color: #666;
}

.password-toggle:hover .password-toggle-icon {
  color: #333;
}

.form-input {
  padding-right: 40px;
}
