.page-wrapper {
  max-width: 100%;
  width: 500px;
  margin: 0 auto;
  padding: 20px;
  margin-bottom: 20%;
  font-family: Arial, sans-serif;
}

.title {
  color: #333;
  text-align: center;
}

.tooltip-container {
  position: relative;
  display: inline-block;
  width: 100%;
  text-align: center;
  margin-bottom: 20px;
  font-size: 10px;
}

.chrome-extension-link {
  color: #15202f;
  text-decoration: underline;
  cursor: pointer;
}

.tooltip-text {
  visibility: hidden;
  width: 200px;
  background-color: #555;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px;
  position: absolute;
  z-index: 1;
  bottom: 125%;
  left: 50%;
  margin-left: -100px;
  opacity: 0;
  transition: opacity 0.3s;
}

.tooltip-container:hover .tooltip-text {
  visibility: visible;
  opacity: 1;
}

.input-area {
  margin-bottom: 20px;
}

.textarea {
  width: 100%;
  height: 200px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
  resize: vertical;
}

.word-count {
  text-align: right;
  font-size: 14px;
  color: #666;
  margin-top: 5px;
}

.sum-button {
  background-color: #15202f;
  color: white;
  padding: 10px 20px;
  font-size: 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.button:hover {
  background-color: #667589;
}

.button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.error-message {
  color: red;
  margin-top: 10px;
}

.summary-area {
  margin-top: 20px;
  background-color: #f9f9f9;
  padding: 20px;
  border-radius: 4px;
}

.summary-title {
  color: #333;
  margin-bottom: 10px;
}

.summary-text {
  color: #444;
  line-height: 1.6;
}

@media (max-width: 550px) {
  .page-wrapper {
    width: 400px;
  }
}
@media (max-width: 400px) {
  .page-wrapper {
    width: 300px;
  }
}
