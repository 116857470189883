:root {
  --color-1: #15202f;
  --text-color: #15202f;
  --accent-color: #667589;
  --background-color: #f4f2e9;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.navbar {
  height: 41px;
  background-color: var(--background-color);
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.links-container {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.nav-link {
  height: 100%;
  padding: 0 20px;
  display: flex;
  align-items: center;
  text-decoration: none;
  color: var(--text-color);
  position: relative;
  overflow: hidden;
}

.nav-link::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: var(--accent-color);
  transform: translateX(-100%);
  transition: transform 0.3s ease;
}

.nav-link:hover::after {
  transform: translateX(0);
}

.navbar .home-link {
  margin-right: auto;
  display: flex;
  align-items: center;
  padding: 0;
}
.navbar .logo {
  height: 50px;
  width: auto;
  padding: 0 20px;
}

.navbar svg {
  fill: var(--text-color);
}

#sidebar-active {
  display: none;
}

.open-sidebar-button,
.close-sidebar-button {
  display: none;
}

.less-account-container {
  display: none;
}

.account-container {
  position: relative;
  height: 100%;
  display: flex;
  align-items: center;
}

.account-icon {
  height: 100%;
  display: flex;
  align-items: center;
  padding: 0 25px;
  cursor: pointer;
}

.account-icon:hover svg {
  fill: var(--accent-color);
}

.account-menu {
  position: absolute;
  top: 41px;
  right: 0;
  background-color: var(--background-color);
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  display: flex;
  flex-direction: column;
}

.account-menu .nav-link {
  padding: 12px 16px;
  width: 100%;
}

@media (max-width: 496px) {
  .links-container {
    flex-direction: column;
    align-items: flex-start;
    position: fixed;
    top: 0;
    right: -100%;
    z-index: 10;
    width: 300px;
    background-color: var(--background-color);
    box-shadow: -5px 0 5px rgba(0, 0, 0, 0.25);
    transition: 0.75s ease-out;
  }

  .nav-link {
    height: 10%;
    width: 100%;
    padding: 20px 30px;
    justify-content: flex-start;
  }

  .open-sidebar-button,
  .close-sidebar-button {
    padding: 20px;
    display: block;
  }

  .account-container {
    display: none;
  }

  .less-account-container {
    display: block;
  }

  .less-account-container a {
    height: 90%;
    width: 100%;
    padding: 20px 30px;
    justify-content: flex-start;
  }

  .account-menu {
    position: static;
    box-shadow: none;
    width: 100%;
  }

  .account-menu .nav-link {
    padding: 20px 30px;
  }

  #sidebar-active:checked ~ .links-container {
    right: 0;
  }

  #sidebar-active:checked ~ #overlay {
    height: 100%;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9;
  }
}
