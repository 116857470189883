/* Global styles */
* {
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-style: normal;
}
body {
  margin: 0;
  background-color: #f4f2e9;
}

/* App container */
.app-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.loading-spinner {
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-left-color: #15202f;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
  margin: 100px auto;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  background-color: #333;
  color: #fff;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000;
  transition: top 0.3s;
}

.header.hidden {
  top: -100px;
}

.header.visible {
  top: 0;
}

.header-left {
  display: flex;
  align-items: center;
}

.brand-name {
  font-size: 1.5rem;
  color: #fff;
  margin-right: 2rem;
}

.nav-links {
  display: flex;
  gap: 1rem;
}

.nav-links a {
  color: #fff;
  text-decoration: none;
  padding: 0.5rem 1rem;
  border-radius: 5px;
  transition: background-color 0.3s;
}

.nav-links a:hover {
  background-color: #444;
}

.login-upload {
  display: flex;
  align-items: center;
}

.button {
  color: #fff;
  text-decoration: none;
  padding: 0.5rem 1rem;
  background-color: #007bff;
  border-radius: 5px;
  transition: background-color 0.3s;
  margin-right: 1rem;
}

.button:hover {
  background-color: #0056b3;
}

.upload-button {
  margin-right: 1rem;
}

.container {
  padding-top: 60px;
  flex: 1;
}

.footer {
  display: flex;
  justify-content: center;
  padding: 1rem;
  background-color: #15202f;
}
.footer p {
  padding-left: 0%;
  font-size: small;
  color: white;
}
.footer-links {
  padding-left: 10%;
}

.footer a {
  align-items: flex-start;
  color: #fff;
  text-decoration: none;
  margin: 0 1rem;
}

.footer a:hover {
  color: #667589;
}
