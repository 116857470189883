.pl-page-wrapper {
  max-width: 800px;
  margin: 0 auto;
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

h1 {
  font-size: 2.5rem;
  margin-bottom: 1rem;
  color: #333;
}

h2 {
  font-size: 2rem;
  margin-top: 2rem;
  margin-bottom: 1rem;
  color: #555;
}

h3 {
  font-size: 1.5rem;
  margin-top: 1.5rem;
  margin-bottom: 0.75rem;
  color: #666;
}

p,
li {
  font-size: 1rem;
  line-height: 1.5;
  color: #666;
  margin-bottom: 1rem;
}

ul,
ol {
  padding-left: 1.5rem;
  margin-bottom: 1rem;
}

ul {
  list-style-type: disc;
}

ol {
  list-style-type: decimal;
}

.table-of-contents {
  border-radius: 8px;
  padding: 1.5rem;
  margin-bottom: 2rem;
}

.table-of-contents h2 {
  font-size: 1.8rem;
  margin-top: 0;
  margin-bottom: 1rem;
  color: #444;
}

.table-of-contents nav ul {
  list-style-type: none;
  padding-left: 0;
}

.table-of-contents nav ul li {
  margin-bottom: 0.5rem;
}

.table-of-contents nav ul li a {
  color: #15202f;
  text-decoration: none;
  transition: color 0.3s ease;
}

.table-of-contents nav ul li a:hover {
  color: #1a2e47;
  text-decoration: underline;
}

section {
  margin-bottom: 2rem;
  padding-bottom: 2rem;
  border-bottom: 1px solid #eee;
}

section:last-child {
  border-bottom: none;
}
