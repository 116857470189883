@import url("https://maxcdn.bootstrapcdn.com/font-awesome/4.3.0/css/font-awesome.min.css");
@import url(https://fonts.googleapis.com/css?family=Open+Sans:400,300);

body {
  margin: 0;
  padding: 0;
}

.login-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 70vh;
}

.alert {
  margin: 10px 0px;
  padding: 12px;
  border-radius: 5px;
  transition: opacity 1600ms ease-in-out;
  font-family: "Open Sans", sans-serif;
  font-size: 0.9rem;
  font-weight: 300;
  letter-spacing: 1px;
}

.alert:hover {
  cursor: pointer;
}

.alert:before {
  padding-right: 12px;
}

.alert:after {
  content: "\f00d";
  font-family: "FontAwesome";
  float: right;
  padding: 3px;
  &:hover {
    cursor: pointer;
  }
}

.alert-error {
  color: #d8000c;
  background-color: #ffbaba;
  border: 1px solid darken(#ffbaba, 15%);
}

.alert-error:before {
  content: "\f057";
  font-family: "FontAwesome";
}

.alert-info {
  color: #00529b;
  background-color: #bde5f8;
  border: 1px solid darken(#bde5f8, 15%);
}

.alert-info:before {
  content: "\f05a";
  font-family: "FontAwesome";
}

.login-box {
  background-color: white;
  border-radius: 10px;
  padding: 40px;
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.1);
  width: 350px;
  text-align: center;
  animation: fadeIn 0.5s ease-out;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.login-title {
  color: #15202f;
  margin-bottom: 30px;
  font-weight: 600;
}

.form-group {
  margin-bottom: 20px;
}

.form-input {
  width: 100%;
  padding: 12px;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-size: 14px;
  transition: border-color 0.3s ease;
}

.form-input:focus {
  border-color: #15202f;
  outline: none;
}

.login-button,
.google-button {
  width: 100%;
  padding: 12px;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
}

.login-button {
  background-color: #15202f;
  color: white;
  margin-bottom: 15px;
}

.login-button:hover {
  background-color: #1a2e47;
}
.forgot-password-button {
  background: none;
  border: none;
  color: #15202f;
  cursor: pointer;
  font-size: 14px;
  margin-bottom: 15px;
  transition: color 0.3s ease;
}

.forgot-password-button:hover {
  color: #1a2e47;
}

.signup-link {
  font-size: 14px;
  color: #666;
}

.signup-link a {
  color: #15202f;
  text-decoration: none;
  font-weight: 600;
  transition: color 0.3s ease;
}

.signup-link a:hover {
  color: #1a2e47;
}

.error-message {
  color: #fc7577;
  margin-bottom: 15px;
  font-size: small;
}

.loading {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  font-size: 24px;
  color: #15202f;
}

.google-button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 12px;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
  background-color: #4285f4;
  color: white;
  margin-bottom: 15px;
}

.google-button:hover {
  background-color: #3367d6;
}

.chrome-logo {
  margin-left: 10px;
  margin-right: -10px;
}

.google-button span {
  flex-grow: 1;
}

@keyframes inputHighlight {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.02);
  }
  100% {
    transform: scale(1);
  }
}

.form-input:focus {
  animation: inputHighlight 0.3s ease;
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.05);
  }
  100% {
    transform: scale(1);
  }
}

.login-button:hover {
  animation: pulse 1s infinite;
}
