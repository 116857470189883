/* Hero Section */
.hero {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 4rem 2rem;
  max-width: 1200px;
  margin: 0 auto;
}

.hero-content {
  flex: 1;
  padding-right: 2rem;
}

.hero-content h1 {
  font-size: 2.5rem;
  margin-bottom: 1rem;
  color: #333;
}

.hero-p-first {
  font-size: 1.1rem;
  margin-bottom: 2rem;
  color: #666;
}

.cta-button {
  padding: 0.75rem 1.5rem;
  font-size: 1rem;
  background-color: #15202f;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.cta-button:hover {
  background-color: #667589;
}

.hero-p-second {
  font-size: 0.8rem;
  margin-top: 1.5rem;
  color: #666;
  margin-left: 30%;
}

.hero-image {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.hero-img {
  max-width: 100%;
  height: auto;
}

/* Feature Section */
.feature {
  padding-top: 10%;
}
.feature-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem 1rem;
}

.cardContainer {
  display: flex;
  flex-direction: row;
  gap: 2rem;
  flex-wrap: wrap;
}

.featureCard {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1.5rem;
  background-color: white;
  border-radius: 0.5rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  flex: 1;
}

.iconWrapper {
  margin-bottom: 1rem;
  padding: 0.75rem;
  background-color: #e6f2ff;
  border-radius: 9999px;
}

.icon {
  width: 2rem;
  height: 2rem;
  color: #15202f;
}

.title {
  margin-bottom: 0.5rem;
  font-size: 1.25rem;
  font-weight: 600;
  text-align: center;
}

.description {
  text-align: center;
  color: #4b5563;
}

/* Browser Extension Section */
.available {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem 1rem;
}

.available-container {
  max-width: 1200px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
  text-align: center;
}

.available-container p {
  margin: 0;
  font-size: 1.2rem;
}

.available-container img {
  border: 0;
  height: 60px;
}

/* About Section */
.about {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 15%;
  max-width: 1200px;
  margin: 0 auto;
}

.about-image {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.about-img {
  max-width: 100%;
  height: auto;
}

.about-content {
  flex: 1;
  padding-left: 2rem;
}

.about-content h1 {
  font-size: 2.5rem;
  text-indent: 50%;
  margin-bottom: 1rem;
  color: #333;
}

.about-p-first {
  font-size: 1.1rem;
  margin-bottom: 2rem;
  color: #666;
}

/* Product Section */
.product {
  padding-top: 10%;
}
.prod-tiers {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-wrap: wrap;
  padding: 20px;
  gap: 3rem;
}
.tierCard-container {
  width: 300px;
  border-radius: 50px;
  overflow: hidden;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  margin: 20px;
  color: white;
  display: flex;
  flex-direction: column;
  border-color: "#15202f";
}
.tierCard-container button {
  font-size: large;
}

/* Contact Section */
.contact {
  padding-top: 10%;
  padding-bottom: 10%;
}
.contact-container {
  max-width: 80%;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 2rem;
  text-align: center;
  margin-bottom: 5%;
  padding: 1rem 1rem;
}

.contact-container p {
  margin: 0;
  font-size: 1.2rem;
  padding-left: 25%;
}
.contact-button {
  padding: 0.75rem 1.5rem;
  font-size: 1rem;
  background-color: #15202f;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}
.contact-button:hover {
  background-color: #667589;
}

@media (max-width: 833px) {
  /* About Section */
  .about-content h1 {
    text-indent: 0;
  }

  /* Contact Section */
  .contact-container {
    flex-direction: column;
    padding-left: 5%;
  }
  .contact-button {
    margin-left: 20%;
  }
  /* .contact-container p {
    padding-left: 20%;
  }
  .contact-button {
    padding: ;
  } */
}

@media (max-width: 450px) {
  /* Hero Section */
  .hero {
    flex-direction: column;
    padding: 2rem 1rem;
  }

  .hero-content {
    padding-right: 0;
    text-align: center;
    margin-bottom: 2rem;
  }

  .hero-content h1 {
    font-size: 2rem;
  }

  .hero-content p {
    font-size: 1rem;
  }
  .hero-p-second {
    margin-left: 0%;
  }

  .hero-image {
    order: -1;
    margin-bottom: 1rem;
  }

  .hero-img {
    max-width: 80%;
  }
  /* Feature Section */
  .cardContainer {
    flex-direction: column;
  }

  /* Browser Extenstion Section */
  .available-container {
    flex-direction: row;
    justify-content: center;
    text-align: right;
  }

  .available-container p {
    margin-right: 2rem;
  }

  /* About Section */
  .about {
    flex-direction: column;
    padding: 2rem 1rem;
  }

  /* Contact Section */
  .contact-container {
    flex-direction: column;
  }
}
