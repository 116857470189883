.contact-page-container {
  width: 600px;
  margin: 50px auto;
  padding: 20px;
  border-radius: 8px;
  background-color: #f9f9f9;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
  animation: fadeIn 1s ease-in-out;
}

.contact-header {
  text-align: center;
  font-size: 2rem;
  color: #333;
  margin-bottom: 20px;
  animation: slideInDown 1s ease-in-out;
}

.contact-email {
  text-align: center;
  display: flex;
  flex-direction: row-reverse;
  font-size: 11px;
  color: #9e9e9e;
}

.contact-form {
  display: flex;
  flex-direction: column;
}

.form-group {
  margin-bottom: 15px;
}

label {
  font-weight: bold;
  color: #555;
  margin-bottom: 5px;
}
.none {
  color: grey;
  font-size: small;
}

input,
select,
textarea {
  width: 100%;
  padding: 10px;
  margin-top: 5px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 1rem;
  transition: all 0.3s ease;
}

textarea {
  resize: vertical;
  max-height: 500px;
  min-height: 100px;
}

input:focus,
select:focus,
textarea:focus {
  border-color: #15202f;
  box-shadow: 0 0 5px rgba(21, 32, 47, 0.5);
}

.submit-btn {
  padding: 10px 20px;
  background-color: #15202f;
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
  animation: buttonFadeIn 2s ease;
}

.submit-btn:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.submit-btn:hover {
  background-color: #667589;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes slideInDown {
  from {
    transform: translateY(-50px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes buttonFadeIn {
  from {
    opacity: 0;
    transform: scale(0.9);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}
@media (max-width: 625px) {
  .contact-page-container {
    width: 450px;
  }
}
@media (max-width: 480px) {
  .contact-page-container {
    width: 300px;
  }
}
