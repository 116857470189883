.verify-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  min-height: 70vh;
}

.verify-card {
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 2rem;
  width: 100%;
  max-width: 400px;
}

.verify-title {
  font-size: 1.5rem;
  font-weight: bold;
  text-align: center;
  margin-bottom: 0.5rem;
}

.verify-description {
  text-align: center;
  color: #6b7280;
  margin-bottom: 0.5rem;
}

.verify-content {
  text-align: center;
}

.verify-icon {
  color: #15202f;
  width: 56px;
  height: 56px;
}

.verify-small-text {
  font-size: 0.875rem;
  color: #6b7280;
  margin-top: 1rem;
}

.verify-button {
  display: block;
  width: 100%;
  padding: 0.5rem 1rem;
  margin-top: 1.5rem;
  background-color: #15202f;
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s;
}

.verify-button:hover {
  background-color: #667589;
}
