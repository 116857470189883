.guide-container {
  min-height: 70vh;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
}

.guide-card {
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 600px;
}

.guide-header {
  padding: 32px 32px 24px;
}

.guide-title {
  font-size: 24px;
  font-weight: bold;
  color: #1f2937;
  margin-bottom: 8px;
}

.guide-subtitle {
  color: #6b7280;
  font-size: 16px;
}

.guide-sections {
  padding: 0 32px 32px;
}

.section {
  margin-bottom: 16px;
  border: 1px solid #e5e7eb;
  border-radius: 6px;
}

.section-button {
  width: 100%;
  padding: 16px;
  background: none;
  border: none;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: background-color 0.2s;
}

.section-button:hover {
  background-color: #f3f4f6;
}

.section-header {
  display: flex;
  align-items: center;
}

.section-icon-container {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f3f4f6;
  border-radius: 50%;
  margin-right: 12px;
}

.section-icon-container svg {
  color: #1f2937;
}

.section-title {
  font-size: 18px;
  font-weight: 500;
  color: #1f2937;
}

.section-content {
  padding: 0 16px 16px;
}

.steps-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.step-item {
  display: flex;
  align-items: flex-start;
  padding: 8px 0;
  cursor: pointer;
  transition: opacity 0.2s;
}

.step-item:hover {
  opacity: 0.7;
}

.step-icon {
  margin-right: 12px;
  flex-shrink: 0;
}

.step-icon.completed {
  color: #10b981;
}

.step-icon.uncompleted {
  color: #9ca3af;
}

.step-text {
  color: #4b5563;
  flex-grow: 1;
}

.step-completed {
  color: #9ca3af;
  text-decoration: line-through;
}
